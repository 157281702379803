<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">Leave Groups</h2>
          </div>
          <div class="col text-end">
              
          </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <div v-else class="row t-layout">
          <div v-if="$store.state.permittedActions.includes(151)" class="col-lg-6 mb-3">
              <div class="card">
            <div class="card-header">
              
            </div>
            <div class="card-body">
                <flash-message class="myCustomClass"></flash-message>
                <form  @submit.prevent="onSubmit($event)">
                    <div class="mb-3">
                        <label class="form-label">Leave group name</label>
                        <input 
                            class="form-control"
                            v-model="$v.form.leavegroup.$model"
                            :validator="$v.form.leavegroup"
                        />
                        <span
                            class="error "
                            v-if="$v.form.leavegroup.$error == true"
                            >Please enter leave group name
                        </span>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Description</label>
                        <input 
                            type="text"
                            class="form-control"
                            v-model="$v.form.description.$model"
                            :validator="$v.form.description"
                        />
                        <span
                            class="error "
                            v-if="$v.form.description.$error == true"
                            >Please enter description </span
                        >
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Leave privileges</label>
                        <b-form-select
                            class="form-select"
                            aria-label="Default select example"
                            v-model="$v.form.leaveprivileges.$model"
                            :options="leaveprivileges"
                            multiple
                        ></b-form-select>  
                            <span
                                class="error "
                                v-if="$v.form.leaveprivileges.$error == true"
                                >Please select leaveprivileges </span
                            >
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Status</label>
                        <b-form-select
                            class="form-select"
                            aria-label="Default select example"
                            v-model="$v.form.status.$model"
                            :options="status"
                            
                        ></b-form-select>  
                            <span
                                class="error "
                                v-if="$v.form.status.$error == true"
                                >Please select status </span
                            >
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-outline-primary" >{{ this.editing == true ? 'Update' : 'Save' }}</button>
                    </div>
                </form>
            </div>
          </div>
          </div>
          <div :class="$store.state.permittedActions.includes(151) ? 'col-lg-6 mb-3': 'col-lg-12 mb-3'">
              
            <div v-if="this.$store.state.loader">
                <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
            </div>
            <div v-else class="card">
            <div class="card-header">
            </div>
            <div class="card-body">
              
              <b-table 
                class="table table-custom"
                :items="leaveGroups.lg"
                :fields="fields"
                show-empty
                sticky-header
              >
              <template v-slot:cell(leave_group)="data">
                  {{ data.item.leavegroup }}
              </template>
              <template v-slot:cell(description)="data">
                  {{ data.item.description }}
              </template>
              <template v-slot:cell(privilege)="data">
                  {{ getLeaveType(data.item.leaveprivileges)  }}
              </template>
              <template v-slot:cell(status)="data">
                  {{ data.item.status == '1' ? 'Active' : 'Disabled' }}
              </template>
              <template v-slot:cell(action)="data">
                  <div class="d-inline-block">
                      <div class="d-flex justify-content-md-around">
                          <a v-if="$store.state.permittedActions.includes(152)" class="button button1" title="Edit" @click.prevent="editLeaveGroup(data.item.id)"><i class="icon-pencil"></i></a>
                          <a v-if="$store.state.permittedActions.includes(153)" class="button button1" title="Delete" @click.prevent="deleteLeaveGroup(data.item.id)"><i class="icon-bin"></i></a>
                      </div>
                  </div>
              </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
   data(){
       return {
           editing: false,
           form: {
               leavegroup: null,
               description: null,
               leaveprivileges: [],
               status: null
           },
           status: [
               {
                   value: '1',
                   text: 'Active'
               },
               {
                   value: '0',
                   text: 'Disabled'
               }
           ],
           fields:[
               {
                   key: 'leave_group',
                   tdAttr: { 'custom-label'  : "Leave Group:"}
               },
               {
                   key: 'description',
                   tdAttr: { 'custom-label'  : "Description:"}
               },
               {
                key: 'privilege',
                   tdAttr: { 'custom-label'  : "Privilege:"}
               },
                {
                key: 'status',
                    tdAttr: { 'custom-label'  : "Status:"}
               },
               {
                   key: 'action',
                   tdAttr: { 'custom-label'  : "Action:"}
               }
           ]
       }
   },
   validations: {
       form: {
           leavegroup: {
               required
           },
           description: {
               required
           },
           leaveprivileges: {
               required
           },
            status: {
                required
            }  
           
       }
   },
   computed:{
       leaveGroups(){
           return this.$store.state.leaveGroupsData;
       },
       leaveprivileges(){
           return this.$store.state.leaveGroupsData.lt.map((lt) =>{
               return {
                   value: lt.id,
                   text: lt.leavetype
               };
           })
       }
   },
   created(){
       this.$store.dispatch('switchLoader', true);
       this.$store.dispatch('loadLeaveGroupsData');
   },
  
   methods:{
       editLeaveGroup(id){
           let ltDetails = this.leaveGroups.lg.filter((lg) => {
               return lg.id == id;
           })[0];
           this.form.leavegroup = ltDetails.leavegroup;
           this.form.description = ltDetails.description;
           this.form.leaveprivileges.push(ltDetails.leaveprivileges);
           this.form.status = ltDetails.status;
           this.form.id = ltDetails.id;
           this.editing= true;
           
       },
       getLeaveType(leaveprivileges){
           return this.leaveGroups.lt.filter((lt) => lt.id == leaveprivileges )[0].leavetype;
       },
       onSubmit(event){
            this.$v.$touch();
            if (this.$v.$invalid) return;
            var url ='';
            if(this.editing == false){
                 url = '/fields/leavetype/leave-groups/add';
            }
            else{
                 url = '/fields/leavetype/leave-groups/update';
            }
                axios
                    .post(url, this.form)
                    .catch(({ response }) => {
                    this.flashMessage.error({
                        message: "Something went wrong!",
                        time: 3000,
                    });
                    })
                    .then(({ data }) => {
                    if (data.success) {
                        this.form.leavegroup = null;
                        this.form.description = null;
                        this.form.leaveprivileges= [];
                        this.form.status = null;
                        this.editing = false;
                        this.flashMessage.success({
                        message: data.success,
                        time: 3000,
                        });
                        this.$store.dispatch('loadLeaveGroupsData');
                    } else {
                        this.flashMessage.error({
                        message: data.error,
                        time: 3000,
                        });
                    }
                    });
       },
       deleteLeaveGroup(id){
           if(confirm('Are you sure?')){
                axios.get("/fields/leavetype/leave-groups/delete/"+id)
                    .catch(({ response }) => {
                    this.flashMessage.error({
                        message: "Something went wrong!",
                        time: 3000,
                    });
                    })
                    .then(({ data }) => {
                    if (data.success) {
                        this.form.department = null;
                        this.flashMessage.success({
                        message: data.success,
                        time: 3000,
                        });
                        this.$store.dispatch('loadLeaveGroupsData');
                    } else {
                        this.flashMessage.error({
                        message: data.error,
                        time: 3000,
                        });
                    }
                    });
            }
       }
   }
}
</script>
